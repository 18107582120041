/* import __COLOCATED_TEMPLATE__ from './page-header.hbs'; */
/* RESPONSIBLE TEAM: team-actions */

import { action } from '@ember/object';
import Component from '@glimmer/component';

import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    searchString: string | null;
    searchForApps: (query: string) => void;
  };
}

export default class PageHeader extends Component<Signature> {
  @action openLinkInNewTab(url: string) {
    safeWindowOpen(url, '_blank');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Appstore::Listing::PageHeader': typeof PageHeader;
    'appstore/listing/page-header': typeof PageHeader;
  }
}

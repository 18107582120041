/* import __COLOCATED_TEMPLATE__ from './avatar-emoji-selector-component.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { computed } from '@ember/object';
import Component from '@ember/component';
import intermoji from '@intercom/intermoji';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default Component.extend({
  tagName: '',

  selectedEmoji: null,
  emoji: intermoji,
  emojiSize: 16,
  emojiImageClass: 'emoji__image avatar__emoji__image',

  displayableSelectedEmoji: computed('selectedEmoji', function () {
    if (!intermoji.isSupportedUnicode(this.selectedEmoji)) {
      return;
    }

    return this.emoji.fallbackImage(
      this.emojiSize,
      intermoji.getEmojiFromSupportedTwemoji(this.selectedEmoji),
      this.emojiImageClass,
    );
  }),
  htmlSafedisplayableSelectedEmoji: computed('displayableSelectedEmoji', function () {
    return sanitizeHtml(this.displayableSelectedEmoji);
  }),
});

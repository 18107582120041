/* import __COLOCATED_TEMPLATE__ from './train-answer-side-drawer-body.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import TrainExistingConfirmation from 'embercom/components/notifications/train-existing-confirmation';

export default class TrainAnswerSideDrawerBody extends Component {
  @service intercomEventService;
  @service notificationsService;
  @service appService;
  @service router;
  @service store;

  @tracked answers = null;
  @tracked language = null;
  @tracked status = 'live';

  @tracked answerInTraining = null;

  get app() {
    return this.appService.app;
  }

  constructor() {
    super(...arguments);
    this.loadAnswers.perform();
  }

  @task({ restartable: true })
  *loadAnswers() {
    this.answers = yield this.store.findAll('custom-answers/custom-answer', {
      reload: true,
      include: 'stats',
    });
  }

  @action
  filterByLanguage(languageCode) {
    this.language = languageCode;
  }

  @action
  filterByStatus(status) {
    this.status = status;
  }

  @action
  trainExistingAnswer(answer) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'suggested_answers_drawer_training_train_existing_button',
      answer_id: answer.id,
      cluster_id: this.args.trainingCluster.clusterId,
      cluster_language: this.args.trainingCluster.language,
    });
    this.addExampleToExistingAnswer.perform(answer);
  }

  @task({ drop: true })
  *addExampleToExistingAnswer(selectedAnswer) {
    try {
      this.answerInTraining = selectedAnswer;

      let answer = yield post(
        `/ember/custom_answers/custom_answers/${selectedAnswer.id}/add_example_questions`,
        {
          app_id: this.appService.app.id,
          example_questions: [
            {
              text: this.args.trainingCluster.text,
              identifier: this.args.trainingCluster.clusterId,
              addedFrom: 'unanswered_questions_side_drawer',
            },
          ],
        },
      );

      this.notificationsService.notifyConfirmationWithModelAndComponent(
        answer,
        TrainExistingConfirmation,
      );
    } catch (error) {
      this.notificationsService.notifyError(`There was an error updating your existing answer`);
      throw error;
    } finally {
      this.answerInTraining = null;
    }
  }

  @action
  createNewAnswer(data) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'suggested_answers_drawer_training_create_new_button',
      cluster_id: this.args.trainingCluster.clusterId,
      cluster_language: this.args.trainingCluster.language,
      cluster_archetype: this.args.trainingCluster.text,
    });

    this.router.transitionTo('apps.app.automation.fin-ai-agent.custom-answers.new', {
      queryParams: {
        exampleId: data.clusterId,
        exampleSource: 'answers_clusters_side_drawer',
        exampleText: data.text,
        expandSelectedExamples: true,
        language: data.language,
        transitionBackTo: 'apps.app.automation.fin-ai-agent.custom-answers.unanswered-questions',
      },
    });
  }
}

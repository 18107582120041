/* import __COLOCATED_TEMPLATE__ from './terminal-menu.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class TerminalMenu extends Component {
  @service appService;
  @service intercomEventService;
  @service intl;

  groupList = this.availableListItems;

  get app() {
    return this.appService.app;
  }

  get endStepData() {
    return this.args.endStateStep;
  }

  get availableListItems() {
    let items = [
      {
        text: this.intl.t(
          'components.operator-flows.editor.add-a-step.add.terminal-menu.standard-reply',
        ),
        icon: 'respond',
        value: 'show_standard_replies',
      },
      {
        text: this.intl.t(
          this.app.canUseStandalone
            ? 'components.operator-flows.editor.add-a-step.add.terminal-menu.fin-hands-over'
            : 'components.operator-flows.editor.add-a-step.add.terminal-menu.pass-to-teammate',
        ),
        icon: 'team',
        value: 'pass_to_team',
      },
      {
        text: this.intl.t(
          'components.operator-flows.editor.add-a-step.add.terminal-menu.create-ticket',
        ),
        icon: 'ticket',
        value: 'send_ticket',
      },
      {
        text: this.intl.t(
          'components.operator-flows.editor.add-a-step.add.terminal-menu.resolve-conversation',
        ),
        icon: 'rounded-check',
        value: 'resolve',
      },

      {
        text: this.intl.t(
          'components.operator-flows.editor.add-a-step.add.terminal-menu.finish-answer',
        ),
        icon: 'stop',
        value: 'finish_answer',
      },
    ];

    if (this.app.canUseStandalone) {
      // Remove send ticket option for standalone workspaces
      items = items.filter((item) => item.value !== 'send_ticket');
    }

    return [
      {
        items,
      },
    ];
  }

  get selectedValue() {
    return this.endStepData.endingType;
  }

  @action updateEndingType(newStepType) {
    set(this.endStepData, 'endingType', newStepType);
    set(
      this.endStepData,
      'progressToTriage',
      newStepType === 'pass_to_team' && this.app.canUseStandalone,
    );

    this.resetAdditionalStepData(newStepType);

    if (this.args.visualBotBuilderStep) {
      this.intercomEventService.trackAnalyticsEvent({
        ...this.args.endStateStep.analyticsData,
        object: 'visual_builder_step_answer_terminal_ending_type',
        action: 'selected',
      });
    }
  }

  @action resetAdditionalStepData() {
    if (this.args.endStateStep.simpleActionRule?.actions?.length > 0) {
      this.args.endStateStep.simpleActionRule.actions = [];
    }

    if (this.args.endStateStep.followUpRules.length > 0) {
      this.args.endStateStep.followUpRules = [];
    }
    if (this.endStepData.ticketTypeId) {
      this.endStepData.ticketTypeId = undefined;
    }
  }
}

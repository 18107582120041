/* import __COLOCATED_TEMPLATE__ from './external-uninstall-modal.hbs'; */
/* RESPONSIBLE TEAM: team-actions */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import redirect from 'embercom/lib/redirect';
import { inject as service } from '@ember/service';
import type RegionService from 'embercom/services/region-service';

interface Signature {
  Args: {
    appPackage: $TSFixMe;
    closeModal: () => void;
  };
}

export default class ExternalUninstallModal extends Component<Signature> {
  @service declare regionService: RegionService;

  @action
  redirectToExternalPage() {
    let stripeAppUrl;
    if (this.regionService.isEU) {
      stripeAppUrl =
        'https://dashboard.stripe.com/settings/apps/com.intercom.eu.stripe-intercom-app';
    } else if (this.regionService.isAU) {
      stripeAppUrl =
        'https://dashboard.stripe.com/settings/apps/com.intercom.au.stripe-intercom-app';
    } else {
      stripeAppUrl = 'https://dashboard.stripe.com/settings/apps/com.intercom.stripe-intercom-app';
    }

    redirect(stripeAppUrl);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ExternalUninstallModal: typeof ExternalUninstallModal;
    'external-uninstall-modal': typeof ExternalUninstallModal;
  }
}

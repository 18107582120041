/* import __COLOCATED_TEMPLATE__ from './answer-header.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { ANSWER_BOT_ESSENTIAL_ID } from 'embercom/lib/billing';
import { RESOLUTION_BOT_REQUIRED_PERMISSION } from 'embercom/lib/operator/resolution-bot/constants';
import type IntlService from 'ember-intl/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type RouterService from '@ember/routing/router-service';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

interface Signature {
  Args: {
    answer: $TSFixMe; // embercom/models/custom-answers/custom-answer
    save?: () => TaskGenerator<void>;
    openTestAnswerModal: () => void;
    afterCancelRouteName?: string;
    isEditing: boolean;
  };
}

export default class AnswerHeader extends Component<Signature> {
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomConfirmService: IntercomConfirmService;

  get app() {
    return this.appService.app;
  }

  answerBotPlanId = ANSWER_BOT_ESSENTIAL_ID;

  get saveButtonIsEnabled() {
    return (
      this.args.answer.hasUnsavedChanges &&
      this.args.answer.isValid &&
      this.args.answer.isNotUploading
    );
  }

  get canBeSetLive() {
    return this.args.answer.titleIsComplete && this.args.answer.answerCanBeSetLive;
  }

  get answerHasChangedRuleset() {
    return this.args.answer.ruleset.hasDirtyAttributes;
  }

  get isSaving() {
    if (!this.args.save) {
      return false;
    }
    return taskFor(this.args.save).isRunning;
  }

  @dropTask
  *updateAnswerStatus(status: 'live' | 'paused') {
    let answer = this.args.answer;
    answer.set('status', status);

    try {
      yield answer.save();
      this.intercomEventService.trackEvent('answer-bot-interacted');
      if (status === 'live') {
        this.intercomEventService.trackEvent(`answer-bot-answer-saved-live`);
      }
      let message =
        status === 'live'
          ? this.intl.t('operator.resolution-bot-answers.notifications.answer-was-set-live')
          : this.intl.t('operator.resolution-bot-answers.notifications.answer-was-paused');
      this.notificationsService.notifyConfirmation(message);
    } catch (e) {
      let actionText =
        status === 'live'
          ? this.intl.t('operator.resolution-bot-answers.notifications.pause-this-answer')
          : this.intl.t('operator.resolution-bot-answers.notifications.set-this-answer-live');
      answer.rollbackAttributes();
      this.notificationsService.notifyError(actionText);
    }
  }

  @action
  async setLive() {
    if (!this.app.hasAnswerBot && this.app.answerBotIsNotActive) {
      return;
    }

    await this.permissionsService.checkPermission(RESOLUTION_BOT_REQUIRED_PERMISSION);

    if (this.answerHasChangedRuleset) {
      this.intercomEventService.trackEvent('answer-bot-changed-answer-audience');
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'set_live',
      object: this.args.answer,
      context: 'top_nav',
    });
    taskFor(this.updateAnswerStatus).perform('live');
  }

  @action
  openTestAnswerModal() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'rb_preview_answer_modal',
      context: 'top_nav',
    });

    this.args.openTestAnswerModal();
  }

  @action
  async pause() {
    await this.permissionsService.checkPermission(RESOLUTION_BOT_REQUIRED_PERMISSION);

    this.intercomEventService.trackAnalyticsEvent({
      action: 'paused',
      object: this.args.answer,
      context: 'top_nav',
    });
    taskFor(this.updateAnswerStatus).perform('paused');
  }

  @action
  editAnswer() {
    let answer = this.args.answer;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'edit_answer',
    });
    let route = `${this.app.answersRoute}.answer.edit`;
    this.router.transitionTo(route, answer.get('id'));
  }

  @action
  saveChanges() {
    if (!this.args.save) {
      return;
    }
    this.intercomEventService.trackEvent('answer-bot-interacted');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'saved',
      object: this.args.answer,
      context: 'top_nav',
    });
    if (this.answerHasChangedRuleset) {
      this.intercomEventService.trackEvent('answer-bot-changed-answer-audience');
    }
    return taskFor(this.args.save).perform();
  }

  @action
  cancel() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'cancel_editor',
      context: 'top_nav',
    });

    this.args.answer.rollbackAttributes();

    this.router.transitionTo(
      this.args.afterCancelRouteName || 'apps.app.automation.fin-ai-agent.custom-answers',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Answers::AnswerHeader': typeof AnswerHeader;
  }
}

/* import __COLOCATED_TEMPLATE__ from './header-component.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { and, readOnly, bool } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { isAny, ternary } from '@intercom/pulse/lib/computed-properties';
import numericFormatter from 'embercom/lib/numeric-formatter';
import numberFormatter from 'embercom/lib/number-formatter';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  customer: null,
  description: null,
  buttonDisabled: null,
  isEditingSubscription: null,

  priceSuffix: 'sign_up.new.product_cards.per_month',
  buttonLabel: null,
  totalAmount: null,
  totalAmountIncludesCoupon: bool('customer.couponCode'),
  formattedTotalAmount: computed('totalAmount', function () {
    let amount = this.totalAmount;
    return amount % 1 === 0 ? numberFormatter(amount) : numericFormatter(amount, 2, true);
  }),
  hasTrialAvailable: isAny('app.products', 'trialable'),
  hideButton: and('customer.hasActiveSubscription', 'app.validEarlyStageApplicant'),
  priceClass: ternary(
    'totalAmountIncludesCoupon',
    'settings__billing__overview__price-copy',
    'settings__billing__overview__price-copy-no-coupon',
  ),

  priceCopy: computed(
    'hasTrialAvailable',
    'customer.hasActiveSubscription',
    'buttonDisabled',
    'isEditingSubscription',
    function () {
      if (this.get('customer.hasActiveSubscription')) {
        if (this.buttonDisabled || !this.isEditingSubscription) {
          return 'sign_up.new.your_total';
        }

        return 'sign_up.new.new_price';
      }

      if (this.hasTrialAvailable) {
        return 'sign_up.new.total_after_trial';
      }

      return 'sign_up.new.total_starting_price';
    },
  ),
});

/* import __COLOCATED_TEMPLATE__ from './prosemirror.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { INLINE_CONTROL_BLOCK_TYPES } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';

class BannerComposerConfig extends BaseConfig {
  autoFocus = false;
  allowedBlocks = ['paragraph'];
  allowTextAlignment = false;
  singleBlockMode = true;
  allowedInline = ['bold', 'italic', 'anchor'];
  hideFromTextFormatter = [INLINE_CONTROL_BLOCK_TYPES];
  experimental = {
    hideInsertersOnMouseOut: true,
  };

  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'fallback-editor' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: false } },
  ];

  constructor({ app, eventService, resolver, placeholder }) {
    super();

    this.placeholder = placeholder;
    this.analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'banner',
          place: 'composer',
          ...attrs,
        });
      },
    };
    this.templating = { picker: 'common/attribute-picker', resolver };
  }

  setupContentAnchorEditor() {
    this.tools = [
      ...this.tools.filter((x) => x.name !== 'anchor-editor'),
      { name: 'content-anchor-editor', options: { showHelpLinkHeader: true } },
    ];
    this.experimental = {
      ...this.experimental,
    };
  }
}

export default class Prosemirror extends Component {
  blocksDoc;
  @service appService;
  @service attributeService;
  @service contentEditorService;
  @service intercomEventService;
  @service intl;
  @service store;

  constructor() {
    super(...arguments);
    this.blocksDoc = new BlocksDocument(this.localizedBannerContent.blocks);
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    let config = new BannerComposerConfig({
      app: this.app,
      eventService: this.intercomEventService,
      resolver: this.resolver,
      placeholder: this.intl.t(
        'components.banners.editor.content.composer.prosemirror.placeholder',
      ),
    });

    config.setupContentAnchorEditor();

    return config;
  }

  get localizedBannerContent() {
    return this.args.localizedBannerContent;
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      includeAppAttributes: true,
      selectedEvents: this.selectedEvents,
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }

  get serializedBlocks() {
    return this.localizedBannerContent?.blocks.serialize();
  }

  @action updateBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.localizedBannerContent.blocks = blockFragments;
  }
}

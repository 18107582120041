/* import __COLOCATED_TEMPLATE__ from './more-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { dropTask, restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

import Component from '@glimmer/component';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';

import { RESOLUTION_BOT_REQUIRED_PERMISSION } from 'embercom/lib/operator/resolution-bot/constants';

interface Args {
  answer: $TSFixMe;
}

export default class MoreDropdown extends Component<Args> {
  @service appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service notificationsService: $TSFixMe;
  @service permissionsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;
  @service declare intercomConfirmService: IntercomConfirmService;

  @tracked taggingModalOpened = false;
  @tracked showDuplicateModal = false;

  get app() {
    return this.appService.app;
  }

  get groupList() {
    return [
      {
        items: [
          this.app.canUseStandalone
            ? undefined
            : {
                text: this.intl.t('outbound.content-editor.headers.more-dropdown.duplicate'),
                onSelectItem: () => this.setShowDuplicateModal(),
                icon: 'duplicate',
              },
          this.app.canUseStandalone
            ? undefined
            : {
                text: this.intl.t('outbound.content-editor.headers.more-dropdown.tag-option'),
                onSelectItem: () => this.setTaggingModalOpened(),
                icon: 'tag',
              },
          {
            text: this.intl.t('operator.resolution-bot-answers.editor.delete-answer'),
            onSelectItem: () => taskFor(this.delete).perform(),
            icon: 'delete',
          },
        ].compact(),
      },
    ];
  }

  setShowDuplicateModal() {
    this.showDuplicateModal = true;
  }

  setTaggingModalOpened() {
    this.taggingModalOpened = true;
  }

  @dropTask
  *delete(): TaskGenerator<void> {
    try {
      yield this.permissionsService.checkPermission(RESOLUTION_BOT_REQUIRED_PERMISSION);
    } catch (e) {
      return;
    }

    let confirmed = yield taskFor(this.confirmDeleteAnswer).perform();

    if (!confirmed) {
      return;
    }

    try {
      yield this.args.answer.destroyRecord();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'deleted',
        object: this.args.answer,
      });
      this.intercomEventService.trackEvent('answer-bot-interacted');
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.resolution-bot-answers.notifications.deletion-success'),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('operator.resolution-bot-answers.notifications.deletion-failed'),
      );
    }
    this.router.transitionTo('apps.app.automation.fin-ai-agent.custom-answers');
  }

  @restartableTask
  *confirmDeleteAnswer(): TaskGenerator<void> {
    return yield this.intercomConfirmService.confirm({
      title: this.intl.t('operator.resolution-bot-answers.notifications.delete-this-answer'),
      body: this.intl.t('operator.resolution-bot-answers.notifications.delete-this-status-answer', {
        status: this.args.answer.status,
      }),
      confirmButtonText: this.intl.t(
        'operator.resolution-bot-answers.notifications.delete-button-label',
      ),
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Answers::MoreDropdown': typeof MoreDropdown;
    'answers/more-dropdown': typeof MoreDropdown;
  }
}

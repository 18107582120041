/* import __COLOCATED_TEMPLATE__ from './facebook-card.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
export default class FacebookCard extends Component {
  @service appService;

  constructor() {
    super(...arguments);
  }

  get isCompact() {
    return this.args.compact;
  }

  get app() {
    return this.appService.app;
  }
}

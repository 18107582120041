/* import __COLOCATED_TEMPLATE__ from './invite-teammates-form.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class InviteTeammatesForm extends Component {
  // TODO: this will be wired up with correct price logic
  price = 99;

  @action noop() {
    // TODO: this will be wired up with correct price logic
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checkout::InviteTeammatesForm': typeof InviteTeammatesForm;
    'checkout/invite-teammates-form': typeof InviteTeammatesForm;
  }
}

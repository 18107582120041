/* import __COLOCATED_TEMPLATE__ from './report-container.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { inject as service } from '@ember/service';
import { FIN_AI_COPILOT_ID, PROACTIVE_SUPPORT_PLUS_ID } from 'embercom/lib/billing';
import moment from 'moment-timezone';
import type IntlService from 'ember-intl/addon/services/intl';
import type Product from 'embercom/models/product';
import type UsageReminderModel from 'embercom/models/usage-reminder';
import type Contract from 'embercom/models/billing/contract';

const REPORTS = [
  'full-seats',
  'copilot-seats',
  'emails-sent',
  'messages-sent',
  'sms',
  'whatsapp',
  'resolutions',
  'phone',
];

const STANDALONE_REPORTS = ['resolutions'];

interface Args {
  contract: Contract;
  pmdasData: $TSFixMe;
  usageReminders: Array<UsageReminderModel>;
  usageLimits: Array<{ metric: Metric; tableName: string; limit: $TSFixMe }>;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class ReportContainer extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare intl: IntlService;

  get reportsFiltered() {
    let reports = this.appService.app.canUseStandalone
      ? STANDALONE_REPORTS
      : REPORTS.map((report) => {
          if (report === 'whatsapp') {
            return this.appService.app.canSeeBulkWhatsapp ? 'whatsapp_outbound_only' : 'whatsapp';
          }
          return report;
        });
    return reports.filter((report) => {
      if (report === 'messages-sent') {
        return this.hasAddOn(PROACTIVE_SUPPORT_PLUS_ID);
      }

      if (report === 'copilot-seats') {
        return this.hasAddOn(FIN_AI_COPILOT_ID);
      }

      return true;
    });
  }

  get metrics() {
    return this.reportsFiltered.map((report) => {
      let translationKey = `billing.usage.pricing-metric.${report}`;
      let reportMetricMap: Record<string, Metric[]> = this.getReportMetricMap();

      if (report === 'whatsapp_outbound_only') {
        return {
          type: report,
          pricingMetrics: reportMetricMap['whatsapp'],
          titleTranslationKey: `${translationKey}.title`,
        };
      }

      return {
        type: report,
        pricingMetrics: reportMetricMap[report],
        titleTranslationKey: `${translationKey}.title`,
      };
    });
  }

  getReportMetricMap() {
    return {
      'full-seats': [Metric.core_seat_count],
      'copilot-seats': [Metric.copilot_seat_count],
      'emails-sent': [Metric.emails_sent],
      'messages-sent': [Metric.messages_sent],
      sms: [
        ...(this.appService.app.isSalesforceContracted
          ? [Metric.sms_segments_sent_received_in_shifted_billing_cycle_au]
          : []),
        Metric.sms_segments_sent_received_in_shifted_billing_cycle_us,
        Metric.sms_segments_sent_received_in_shifted_billing_cycle_ca,
        Metric.sms_segments_sent_received_in_shifted_billing_cycle_uk,
      ],
      whatsapp: this.appService.app.canSeeBulkWhatsapp
        ? [Metric.whatsapp_outbound]
        : [Metric.whatsapp_inbound, Metric.whatsapp_outbound],
      resolutions: [Metric.resolutions_with_custom_answers],
      phone: [Metric.calling],
    };
  }

  hasAddOn(addOn: string) {
    return this.appService.app.activeProducts.some((product: Product) => product.id === addOn);
  }

  get customer() {
    return this.customerService.customer;
  }

  get subscription() {
    return this.customer.subscription;
  }

  get billingPeriodDate() {
    let humanizedDate = 'MMMM DD, YYYY';
    return `${moment.tz(this.customer.currentBillingCycleStartDate, 'UTC').format(humanizedDate)} - ${moment
      .tz(this.customer.currentBillingCycleEndDate, 'UTC')
      .format(humanizedDate)}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::Usage::ReportContainer': typeof ReportContainer;
  }
}

/* import __COLOCATED_TEMPLATE__ from './whatsapp-card.hbs'; */
/* RESPONSIBLE TEAM: team-channels */

import Component from '@glimmer/component';
export default class WhatsappCard extends Component {
  constructor() {
    super(...arguments);
  }

  get isCompact() {
    return this.args.compact;
  }
}

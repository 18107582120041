/* import __COLOCATED_TEMPLATE__ from './prosemirror-chat.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import {
  BaseConfig,
  BlocksDocument,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
  INPUT_RULE_MARKDOWN_ANCHOR,
} from '@intercom/embercom-prosemirror-composer';
import { EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import { EmbercomCanvasNetworkClient } from 'embercom/objects/composer/embercom-canvas-network-client';
import IfElseStatementBlockEditorComposerConfig from 'embercom/objects/composer/config/chat-if-else-statement-block-editor';
import { hasMobileSdkInstalled } from 'embercom/lib/mobile/sdk-version-check';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  allowedBlocks = [
    'paragraph', // The first block specified is the default so paragraph should be first.
    'codeBlock',
    'ifElseStatement',
    'image',
    'messengerCard',
    'orderedList',
    'unorderedList',
    'video',
    'videoFile',
  ];
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = false;
  canvasNetworkClient;
  inserters = {
    appInserter: 'composer/inserters/app-inserter',
    emojiInserter: 'composer/inserters/emoji-inserter',
    ifElseStatementInserter: {
      name: 'composer/if-else-statement-block-editor',
      options: {
        helpUrl: 'https://www.intercom.com/help/en/articles/6359569-using-dynamic-content-in-chat',
      },
    },
    tourInserter: 'composer/inserters/tour-inserter',
    // newsInserter: 'composer/inserters/news-inserter',
  };
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];
  placeholder = '';
  textDirection = 'ltr';
  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'button-editor', options: { showHelpLinkHeader: true } },
    { name: 'fallback-editor' },
    { name: 'media-inserter' },
    { name: 'if-else-statement-editor' },
    { name: 'image-editor', options: { supportAltAttributeEditing: false } },
    { name: 'video-editor' },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    allowedVideoFileTypes: ['video/mp4'],
    allowedAttachmentTypes: ['*'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };

  constructor({ app, eventService, resolver }) {
    super();

    this.analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'chat',
          place: 'composer',
          ...attrs,
        });
      },
    };

    this.canvasNetworkClient = new EmbercomCanvasNetworkClient(app.id);

    this.inserters.ifElseStatementInserter.options.composerConfig =
      new IfElseStatementBlockEditorComposerConfig({ app, eventService, resolver });

    this.templating = { picker: 'common/attribute-picker', resolver };
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
  }

  setupContentAnchorEditor() {
    this.tools = [
      ...this.tools.filter((x) => x.name !== 'anchor-editor'),
      { name: 'content-anchor-editor', options: { showHelpLinkHeader: true } },
    ];
    this.experimental = {
      ...this.experimental,
      showInsertAnchorOption: true,
    };
  }
}

export default class ProsemirrorChatComposer extends Component {
  blocksDoc;
  @service appService;
  @service attributeService;
  @service contentEditorService;
  @service intercomEventService;
  @service store;

  constructor() {
    super(...arguments);
    this.blocksDoc = new BlocksDocument(this.localizedChatContent.blocks);
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    let config = new ComposerConfig({
      app: this.app,
      eventService: this.intercomEventService,
      resolver: this.resolver,
    });

    config.upload.onUploadStart = this.contentEditorService.uploadFileStarted;
    config.upload.onUploadFinish = this.contentEditorService.uploadFileFinished;

    if (this.canUseSmartLinks) {
      config.setupContentAnchorEditor();
    }

    return config;
  }

  get canUseSmartLinks() {
    return this.hasNotMobileSdkInstalled || this.app.canOverrideMobileSmartLinkCondition;
  }

  get hasNotMobileSdkInstalled() {
    return !hasMobileSdkInstalled(this.app);
  }

  get localizedChatContent() {
    // the question mark here is needed due to an ember.js framework bug
    // https://github.com/emberjs/ember.js/issues/18969
    return this.args.chat?.localizedChatContents.firstObject;
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      includeAppAttributes: true,
      selectedEvents: this.selectedEvents,
      transientAttributes: this.seriesTriggerAttributes,
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }

  get seriesTriggerAttributes() {
    return this.contentEditorService.seriesTriggerAttributes;
  }

  get serializedBlocks() {
    return this.localizedChatContent?.blocks.serialize();
  }

  @action updateBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.localizedChatContent.blocks = blockFragments;
  }
}

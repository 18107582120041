/* import __COLOCATED_TEMPLATE__ from './begin-trial-guide-lib.hbs'; */
/* RESPONSIBLE TEAM: team-self-serve */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';
import type GuideLibraryService from 'embercom/services/guide-library-service';

interface Args {
  showUpgradeButton: boolean;
  onUpgrade: () => void;
  transitionToOnboardingHome: () => void;
  showContinueButton: boolean;
  showExtendTrialCTA: boolean;
  requestTrialExtension: () => void;
  validEarlyStageApplicant: boolean;
  transitionToEarlyStageCheckout: () => void;
  canUseEarlyStageDetection: boolean;
  triggerAnalytics: () => void;
  upgradeButtonLabel: string;
  trialPlanName: string;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class BeginTrialGuideLib extends Component<Signature> {
  @service declare guideLibraryService: GuideLibraryService;
  @service declare cardlessTrialService: CardlessTrialService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Banners::BeginTrialGuideLib': typeof BeginTrialGuideLib;
  }
}

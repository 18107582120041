/* import __COLOCATED_TEMPLATE__ from './plan-card-container.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import {
  PLAN_DATA,
  PRICING_5_X_CORE_PLANS,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
  PRICING_5_EARLY_STAGE_BUNDLE,
} from 'embercom/lib/billing';
import { Metric } from 'embercom/models/data/pricing/metric-types';

interface Args {
  earlyStageGraduation: any;
  customer: any;
  allFeatures: string[];
  availableEssentialFeatures: string[];
  availableAdvancedFeatures: string[];
  availableExpertFeatures: string[];
  availablePSPFeatures: string[];
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

const MIN_EARLY_STAGE_SEATS = 6;

export default class PlanCardContainer extends Component<Signature> {
  @service intl!: IntlService;
  @service customerService: any;
  @service purchaseAnalyticsService: any;
  @service notificationsService: any;
  @service appService: any;
  @service router: any;

  @tracked showStayOnEarlyStageModal = false;

  @tracked showFeatureComparisonModal = false;

  get earlyStageGraduation() {
    return this.args.earlyStageGraduation;
  }

  get currentStepYearNumber() {
    return this.earlyStageGraduation.currentStepYearNumber;
  }

  get nextStepYearNumber() {
    return this.earlyStageGraduation.nextStepYearNumber;
  }

  get currentDiscountPercentage() {
    return Math.floor(this.earlyStageGraduation.currentStepPercentageDiscount);
  }

  get nextYearDiscountPercentage() {
    return Math.floor(this.earlyStageGraduation.nextStepPercentageDiscount);
  }

  get shouldDisableEarlyStagePlan() {
    return (
      this.earlyStageGraduation.progressionConfirmedByCustomer &&
      this.earlyStageGraduation.isGraduatingToEarlyStage
    );
  }

  get shouldDisableRecommendedPlan() {
    return (
      this.earlyStageGraduation.progressionConfirmedByCustomer &&
      !this.earlyStageGraduation.isGraduatingToEarlyStage
    );
  }

  get earlyStagePlansPrice() {
    return this.customerService.getPriceFromPlanIds(
      PRICING_5_EARLY_STAGE_BUNDLE.map(Number),
      this.earlyStageGraduation.pricingModelForEarlyStagePlan,
    );
  }

  get earlyStageDiscountedPrice() {
    return (
      this.earlyStageAdvancedDiscountedPrice +
      this.proactiveSupportDiscountedPrice +
      this.copilotDiscountedPrice
    );
  }

  get earlyStageAdvancedDiscountedPrice(): number {
    let priceBreakdown = this.earlyStagePriceBreakdown(PRICING_5_X_CORE_ADVANCED_ID);
    let price = priceBreakdown.charges.find(
      (charge: any) => charge.pricing_metric === Metric.core_seat_count,
    ).price;

    return price / 100;
  }

  get proactiveSupportDiscountedPrice() {
    return this.convertedPriceForPlan(PROACTIVE_SUPPORT_PLUS_BASE_ID);
  }

  get copilotDiscountedPrice() {
    return this.convertedPriceForPlan(FIN_AI_COPILOT_BASE_ID);
  }

  get earlyStageFullPrice() {
    return this.fullPriceForAmount(this.earlyStageDiscountedPrice);
  }

  get earlyStageAdvancedFullPrice() {
    return this.fullPriceForAmount(this.earlyStageAdvancedDiscountedPrice);
  }

  get proactiveSupportFullPrice() {
    return this.fullPriceForAmount(this.proactiveSupportDiscountedPrice);
  }

  get copilotFullPrice() {
    return this.fullPriceForAmount(this.copilotDiscountedPrice);
  }

  get recommendedCorePlan() {
    let planIds = this.earlyStageGraduation.recommendedPlanIds;
    if (this.shouldDisableRecommendedPlan) {
      planIds = this.earlyStageGraduation.planIds;
    }

    return planIds.find((id: number) => PRICING_5_X_CORE_PLANS.includes(id.toString()));
  }

  get isEssentialPlan() {
    return this.recommendedCorePlan.toString() === PRICING_5_X_CORE_ESSENTIAL_ID;
  }

  get recommendedCorePlanData() {
    return PLAN_DATA[this.recommendedCorePlan];
  }

  get recommendedCorePlanName() {
    return this.recommendedCorePlanData.name;
  }

  get recommendedPricePerSeat() {
    return this.recommendedCorePlanData.pricePerSeat;
  }

  get recommendedSeatPrice() {
    return this.recommendedPricePerSeat * this.coreSeatCount;
  }

  get recommendedLightSeatCount() {
    return this.recommendedCorePlanData.liteSeatLimit;
  }

  get coreSeatCount() {
    return (
      this.appService.app.humanAdminsWithCoreSeat.length || this.appService.app.humanAdmins.length
    );
  }

  get earlyStageSeatCount() {
    return Math.max(MIN_EARLY_STAGE_SEATS, this.coreSeatCount);
  }

  get hasCopilotSeats() {
    return this.appService.app.humanAdminsWithCopilotSeat.length > 0;
  }

  earlyStagePriceBreakdown(planId: string) {
    return this.earlyStagePlansPrice.breakdown.find(
      (breakdown: any) => breakdown.plan_id.toString() === planId,
    );
  }

  convertedPriceForPlan(planId: string): number {
    let amount = this.earlyStagePriceBreakdown(planId).plan_starting_price;
    return amount / 100;
  }

  fullPriceForAmount(discountedAmount: number): number {
    return discountedAmount / (1 - this.nextYearDiscountPercentage / 100);
  }

  @action
  closeEarlyStageModal() {
    this.showStayOnEarlyStageModal = false;
  }

  @action
  openStayOnEarlyStageModal() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'early_stage_plan_card',
      place: 'update_subscription',
      object: 'stay_on_early_stage',
    });
    this.showStayOnEarlyStageModal = true;
  }

  @action
  redirectToPlanBuilder() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'recommended_plan_card',
      place: 'update_subscription',
      object: 'configure_your_new_subscription',
      plan_name: this.recommendedCorePlanName,
    });

    this.router.transitionTo('apps.app.teams-checkout', this.customerService.app.id, {
      queryParams: {
        plan_id: this.recommendedCorePlan,
      },
    });
  }

  @action
  async saveEarlyStagePlans() {
    let priceSetIdentifier = this.earlyStageGraduation.pricingModelForEarlyStagePlan;
    let planIds = this.earlyStageGraduation.earlyStagePlanIds;

    this.earlyStageGraduation.planIds = planIds;
    this.earlyStageGraduation.priceSetIdentifier = priceSetIdentifier;

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'stay_on_early_stage_modal',
      place: 'update_subscription',
      object: 'stay_on_early_stage',
      plan_ids: planIds,
      price_set_identifier: priceSetIdentifier,
    });

    await taskFor(this.saveEarlyStageGraduation).perform();
    this.closeEarlyStageModal();
  }

  @dropTask
  *saveEarlyStageGraduation() {
    try {
      yield this.earlyStageGraduation.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('billing.summary.current-and-future-price-component.success-saving-plan'),
      );
      this.router.transitionTo('apps.app.billing.summary', this.customerService.app.id, {
        queryParams: {
          esChoiceConfirmation: true,
        },
      });
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('billing.summary.current-and-future-price-component.error-saving-plan'),
      );
    }
  }

  @action
  onFeatureComparisonModalClose() {
    this.showFeatureComparisonModal = false;
  }

  @action
  onReviewFeatures() {
    this.showFeatureComparisonModal = true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::UpdateEarlyStageSubscription::PlanCardContainer': typeof PlanCardContainer;
    'billing/update-early-stage-subscription/plan-card-container': typeof PlanCardContainer;
  }
}
